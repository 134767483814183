<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:45:02
-->
<template>
	<div class="registChange">
		<div class="registChange-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">学号</span>
					<el-input class="common-row-ipt" placeholder="请输入学生的学号" v-model="registBindData.studentId" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">注册号</span>
					<el-input class="common-row-ipt" placeholder="请输入正确的注册号" v-model="registBindData.regNum" />
				</div>
			</div>
			<div class="common-row" style="margin-top: 20px;">
				<el-button v-preventReClick @click="submitBind" type="primary">确定更改</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		bindRegistNum,
	} from "@/api/volunteer";
	export default {
		data() {
			return {
				registBindData: {}
			};
		},
		methods: {
			submitBind() {
				let changeData = JSON.parse(JSON.stringify(this.registBindData))
				if(!changeData.studentId) {
					this.$message({
						type: 'error',
						message: '请输入学生的学号!'
					})
					return
				}
				if(!changeData.regNum) {
					this.$message({
						type: 'error',
						message: '请输入正确的注册号!'
					})
					return
				}
				bindRegistNum(changeData).then((res) => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '注册号更改成功!'
						})
						this.this.registBindData = {}
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	.registChange {
		.registChange-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 16px;

				.common-row-ipt {
					width: 400px;
					height: 36px;
					line-height: 36px;
					border: 1px solid #EDF1F7;
					border-left: 0;
				}
			}

		}
	}
</style>
